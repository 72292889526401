<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-sheet rounded="lg">
          <sidebar-menu title="Admin" :items="scopedMenu"/>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="8" md="9">
        <v-sheet
            class="surface mb-10"
            min-height="70vh"
            rounded="lg"
        >
          <router-view/>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SidebarMenu from "@/components/shared/SidebarMenu";
import {mapState} from "vuex";

export default {
  name: 'Admin',
  components: {SidebarMenu},
  computed: {
    ...mapState("app",["loading","adminPageSidebarMenuItems"]),
    ...mapState("user", ["accountRoles"]),

    scopedMenu() {
      return this.adminPageSidebarMenuItems ? this.adminPageSidebarMenuItems.filter(i=> i.role == "*" || this.accountRoles.findIndex(r=> r.name === i.role)>-1 || this.accountRoles.findIndex(r=> r.name === "rootAdmin")>-1):[]
    }

  }
};
</script>