import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,{staticClass:"surface",attrs:{"rounded":"lg"}},[(_vm.title)?_c('h1',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.title)?_c(VDivider,{staticClass:"my-2"}):_vm._e(),_c(VList,{attrs:{"color":"transparent"}},_vm._l((_vm.items),function(n){return _c(VListItem,{key:n.key,attrs:{"link":"","to":n.link,"disabled":n.canFlyCriteriaApplied == true && _vm.flyingStatus.canFly == false}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(" "+_vm._s(n.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(n.text)+" "+_vm._s(n.canFlyCriteriaApplied == true && _vm.flyingStatus.canFly == false ? '(disabled)' : '')+" ")])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }