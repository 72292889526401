<template>
  <v-sheet rounded="lg" class="surface">
    <h1 class="text-center" v-if="title">{{ title }}</h1>
    <v-divider class="my-2" v-if="title"/>
    <v-list color="transparent">
      <v-list-item
          v-for="n in items"
          :key="n.key"
          link
          :to="n.link"
          :disabled="n.canFlyCriteriaApplied == true && flyingStatus.canFly == false"
      >
        <v-list-item-icon>
          <v-icon>
            {{ n.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ n.text }} {{ n.canFlyCriteriaApplied == true && flyingStatus.canFly == false ? '(disabled)' : ''}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-sheet>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "SidebarMenu",
  props:{
    title: {
      type: String,
      default:''
    },
    items: {
      type: Array
    },
  },
  computed: {
    ...mapState("user", ["flyingStatus"]),
  }
}
</script>

<style scoped>

</style>